import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { KeplerApiAuthorizerInterceptor } from '../../interceptors/kepler-api-authorizer.interceptor';

/**
 * Sets Kepler SFDC API requests with an Authorization header necessary to verify the the current user's Okta JWT.
 */
@Injectable()
export class KeplerSfdcInterceptor extends KeplerApiAuthorizerInterceptor {
    constructor(@Inject(OKTA_AUTH) oktaAuth: OktaAuth) {
        super(oktaAuth, environment.api.kepler.sfdc.url);
    }
}
