import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { NavGroup } from '../brain-tab-nav-group.interface';

@Component({
    selector: 'app-brain-tab-nav-group-title',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './brain-tab-nav-group-title.component.html',
    styleUrls: ['./brain-tab-nav-group-title.component.css'],
})
export class BrainTabNavGroupTitleComponent implements OnChanges {
    @Input() group: NavGroup;
    @Input() isActive: boolean;
    @Input() isLightMode: boolean;
    @Input() isDarkMode: boolean;
    @Input() isNavyMode: boolean;
    caretIconPath$: ReplaySubject<string> = new ReplaySubject(1);

    @Output() toggle = new EventEmitter<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isDarkMode?.currentValue == true || changes.isNavyMode?.currentValue == true) {
            this.caretIconPath$.next('/assets/icons/arrow-down-orange.svg');
        } else if (changes.isLightMode?.currentValue == true) {
            this.caretIconPath$.next('/assets/icons/arrow-down-navy.svg');
        }
    }

    handleToggle(e: Event): void {
        e?.stopPropagation();
        this.toggle.emit();
    }
}
