<header role="banner" class="justify-between">
    <!-- Take user to the know.genesys.com home page in a new tab -->
    <a [href]="gknUrl" target="_blank">
        <img
            class="logo"
            alt="Genesys Knowledge Network"
            title="Genesys Knowledge Network"
            height="100%"
            width="100%"
            [src]="logoPath$ | async" />
    </a>
    <button
        *ngIf="!(authStateService.authState$ | async)?.isAuthenticated && (showLoginForm$ | async) == false"
        class="btn"
        [ngClass]="{ 'btn-primary': isLightMode, 'btn-secondary-brand': isDarkMode || isNavyMode }"
        (click)="showLoginForm$.next(true)">
        Sign In
    </button>
    <button
        *ngIf="(showLoginForm$ | async) == true"
        class="btn"
        [ngClass]="{ 'btn-primary': isLightMode, 'btn-secondary-brand': isDarkMode || isNavyMode }"
        (click)="showLoginForm$.next(false)">
        Cancel
    </button>
    <button
        *ngIf="(authStateService.authState$ | async)?.isAuthenticated"
        class="btn"
        [ngClass]="{ 'btn-primary': isLightMode, 'btn-secondary-brand': isDarkMode || isNavyMode }"
        (click)="signOut($event)">
        Sign Out
    </button>
</header>

<section role="main">
    <app-login-form
        *ngIf="(showLoginForm$ | async) == true; else brainTabLayout"
        (signInWithOktaEmitter)="signInWithOkta()"
        [theme]="theme$ | async"></app-login-form>
    <ng-template #brainTabLayout>
        <ng-container *ngIf="(authStateService.authState$ | async).isAuthenticated; else unauthedTop">
            @defer {
                <p *ngIf="firstName$ | async as userName" class="firstname">Welcome, {{ userName }}!</p>
            } @placeholder (minimum 500ms) {
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
            }
        </ng-container>
        <app-brain-tab-search-bar
            [product]="activeAccordion$ | async"
            (searchClick)="handleSubmitSearch($event)"
            (clearClick)="handleClearSearchTerm()">
        </app-brain-tab-search-bar>
        <section class="search-container">
            <app-loading-spinner *ngIf="isSearchLoading$ | async" height="30px" [theme]="theme$ | async">
            </app-loading-spinner>
            <ng-container *ngIf="showSearchResults$ | async">
                <app-brain-tab-search-results
                    [searchTerm]="searchTerm$ | async"
                    [product]="activeAccordion$ | async"></app-brain-tab-search-results>
                <div class="viewall-clear-container">
                    <a [href]="viewAllUrl$ | async" target="_blank">View All</a>
                    <app-vertical-divider [color]="isLightMode ? 'black' : 'white'"></app-vertical-divider>
                    <!--TODO Clear All should be a button for seman1tic HTML`-->
                    <a (click)="handleClearAllResults($event)">Clear Results</a>
                </div>
            </ng-container>
        </section>
        <nav #nav>
            <ul>
                <li *ngFor="let group of navGroups$ | async; trackBy: trackByItem">
                    <app-brain-tab-nav-group-title
                        [group]="group"
                        [isActive]="group.uid === (activeAccordion$ | async)"
                        [isLightMode]="isLightMode"
                        [isDarkMode]="isDarkMode"
                        [isNavyMode]="isNavyMode"
                        (toggle)="setActiveAccordion(group.uid)"></app-brain-tab-nav-group-title>
                    <app-brain-tab-nav-group-links
                        [group]="group"
                        [isActive]="group.uid === (activeAccordion$ | async)"
                        (login)="enableLoginForm()"></app-brain-tab-nav-group-links>
                </li>
            </ul>
        </nav>
    </ng-template>
</section>

<p *ngIf="stage !== 'prod'" class="debug-stage">
    env: {{ stage }}<br />id: {{ oktaClientId }}<br />iss: {{ oktaIssuer }}<br />cb: {{ oktaCallback }}
</p>
