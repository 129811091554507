/**
 * Sorts the given array by a date field, specified by the field argument.
 * Items in the given array without a defined `field` value are sorted to
 * the beginning/end of the array based on the `desc` argument.
 *
 * In any case that an error occurs, the original array is returned.
 *
 * @param arr to sort
 * @param field the date field to elements sort by
 * @param desc true for most recent to least recent. false by default
 * @returns a new array with items sorted by date, or the original array upon error.
 */
export function sortByDate<T>(arr: T[], field: string, desc = false): T[] {
    if (!arr || arr?.length < 2) {
        return arr;
    }

    try {
        const sorted = arr.sort((a, b) => {
            if (a[field] && !b[field]) {
                // 'a' has a date, 'b' does not.
                return 1;
            } else if (!a[field] && b[field]) {
                // 'b' has a date, 'a' does not.
                return -1;
            } else if (!a[field] && !b[field]) {
                // neither 'a' nor 'b' has a date, consider them equal.
                return 0;
            } else {
                return new Date(b[field]).getTime() - new Date(a[field]).getTime();
            }
        });

        if (desc) {
            sorted.reverse();
        }

        return sorted;
    } catch (err) {
        console.error(
            `An unexpected error occurred while sorting the following array by the "${field}" date/time field`,
            { cause: err, data: arr },
        );

        return arr;
    }
}
