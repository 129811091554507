import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, filter, iif, map, switchMap } from 'rxjs';
import { UserService } from 'src/app/shared/services';
import {
    KeplerCoveoSearchPage,
    KeplerCoveoSearchToken,
    KeplerCoveoSearchTokenRequestBody,
    KeplerCoveoSuggestionsResponse,
} from './kepler-coveo.interface';

@Injectable({
    providedIn: 'root',
})
export class KeplerCoveoService {
    constructor(
        private http: HttpClient,
        private userService: UserService,
    ) {}

    /**
     * Gets a hosted search page from the kepler-coveo-api
     * @param pageId search page ID from Coveo dashboard
     * @returns search page or empty observable
     */
    searchPage$(pageId: string): Observable<KeplerCoveoSearchPage> {
        const baseUrl = environment.api.kepler.coveo.url;

        return this.http
            .get<KeplerCoveoSearchPage>(`${baseUrl}/search-page?pageId=${pageId}`)
            .pipe(filter((searchPage: KeplerCoveoSearchPage) => !!searchPage));
    }

    /**
     * Fetches a new search token from the kepler-coveo-api
     * @returns the search token string
     */
    searchToken$(searchHub: string): Observable<string> {
        return this.userService.isAuthenticated$.pipe(
            switchMap((isAuthed) => {
                const url = `${environment.api.kepler.coveo.url}/search-token`;

                return iif(
                    () => isAuthed,
                    this.userService.claims$.pipe(
                        map((claims) => claims.email),
                        switchMap((email) => {
                            return this.http.post<KeplerCoveoSearchToken>(url, {
                                email: email,
                                searchHub: searchHub,
                            } as KeplerCoveoSearchTokenRequestBody);
                        }),
                    ),
                    this.http.post<KeplerCoveoSearchToken>(url, {
                        // No email required for unauthed search token
                        searchHub: searchHub,
                    } as KeplerCoveoSearchTokenRequestBody),
                );
            }),
            map((searchTokenResponse) => searchTokenResponse?.token),
        );
    }

    suggestions$(searchTerm: string): Observable<KeplerCoveoSuggestionsResponse> {
        const params = new URLSearchParams();
        params.set('query', searchTerm);
        params.set('searchHub', environment.search.searchHub);

        const url = new URL(`${environment.api.kepler.coveo.url}/suggestions`);
        url.search = params.toString();

        return this.http
            .get<KeplerCoveoSuggestionsResponse>(url.toString())
            .pipe(filter((res) => res.data?.length > 0));
    }
}
