import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { CaseStatusEnum, MyCase } from '@services';
// import { cases } from './test-data';
import { CommonModule, NgIf } from '@angular/common';
import { sortByDate } from '@utils';

@Component({
    selector: 'app-brain-tab-cases',
    standalone: true,
    imports: [CommonModule, NgIf],
    templateUrl: './brain-tab-cases.component.html',
    styleUrl: './brain-tab-cases.component.css',
})
export class BrainTabCasesComponent implements OnInit {
    @Input() cases: MyCase[] = [];
    viewMyCasesUrl = environment.mySupport.url;
    ngOnInit(): void {
        // uncomment to use this test data
        // this.cases = cases;

        console.log('my cases unfilter: ', this.cases);

        this.cases = this.filterCases(this.cases);
        this.cases = this.sortCases(this.cases);

        console.log('my cases', this.cases);
    }

    private filterCases(cases: MyCase[]): MyCase[] {
        return cases.filter((myCase: MyCase) => {
            return myCase.status == CaseStatusEnum.AWAITING_INFO || myCase.status == CaseStatusEnum.SOLUTION_PROPOSED;
        });
    }

    private sortCases(cases: MyCase[]): MyCase[] {
        return sortByDate(cases, 'updatedDate', false);
    }
}
