import { Inject, inject, Injectable } from '@angular/core';
import { UserRoleUid } from '@enums';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { UserClaims } from '@okta/okta-auth-js';
import { KeplerSfdcCase, KeplerSfdcService, KeplerSfdcUser, KeplerSfdcUserDetail, Product } from '@services';
import { SalesforceContactId } from '@types';
import { BehaviorSubject, filter, from, map, Observable, ReplaySubject, switchMap, tap } from 'rxjs';

export interface MyCase {
    id: string;
    case?: string;
    subject?: string;
    status?: string;
    updatedDate?: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _isAuthenticated = new ReplaySubject<boolean>(1);
    private _subscribedProducts$ = new BehaviorSubject<Product[]>([]);
    private _claims$ = new ReplaySubject<UserClaims>(1);
    private _sfdcUserDetail$ = new ReplaySubject<KeplerSfdcUserDetail>(1);
    private _sfdcContactId: SalesforceContactId | null;
    private _userRole$ = new ReplaySubject<UserRoleUid>(1);
    private _isEmployee$ = new ReplaySubject<boolean>(1);
    private _isCssEmployee$ = new ReplaySubject<boolean>(1);

    static CSS_EVP_ID = 'Scott Cravotta';

    private keplerSfdcService = inject(KeplerSfdcService);

    constructor(
        private authStateService: OktaAuthStateService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    ) {
        this.authStateService.authState$
            .pipe(
                map((state) => state.isAuthenticated),
                tap((isAuthenticated) => this._isAuthenticated.next(isAuthenticated)),
                filter((isAuthenticated: boolean) => {
                    if (!isAuthenticated) {
                        // Some properties still need default values for unauth'ed state, but not all properties. Choose wisely here.
                        this._userRole$.next(UserRoleUid.PUBLIC);
                        this._isEmployee$.next(false);
                        this._isCssEmployee$.next(false);
                    }

                    return isAuthenticated === true; // purposely verbose for clarity
                }),
                switchMap(() => from(this.oktaAuth.getUser())),
                tap((claims: UserClaims) => this._claims$.next(claims)),
                switchMap(() => this.keplerSfdcService.sfdcUserDetail$),
                filter((sfdcUserDetail: KeplerSfdcUserDetail) => !!sfdcUserDetail),
                tap((sfdcUserDetail: KeplerSfdcUserDetail) => {
                    this._sfdcUserDetail$.next(sfdcUserDetail);
                    this._sfdcContactId = sfdcUserDetail.Contacts?.[0]?.Id ?? null;

                    const userRole = this.determineUserRole(sfdcUserDetail);
                    this._userRole$.next(userRole);
                    this._isEmployee$.next(this.isEmployee(userRole, sfdcUserDetail));
                    this._isCssEmployee$.next(this.isCssEmployee(sfdcUserDetail));
                }),
            )
            .subscribe();
    }

    /**
     * Determines the user's role using the the SFDC API UserDetail response data
     * @param {string} sfUserType UserType returned from Salesforce
     */
    private determineUserRole(data: KeplerSfdcUserDetail): UserRoleUid {
        const profileName = data.Profile?.Name;
        if (profileName) {
            if (this.isCsmOrTamUser(profileName)) {
                return UserRoleUid.CSM_TAM;
            } else if (this.isConsultant(profileName)) {
                return UserRoleUid.CONSULTANT;
            }
        }

        const sfUserType = data.User?.UserType;
        switch (sfUserType) {
            case 'PowerPartner':
                return UserRoleUid.PARTNER;
            case 'Standard':
                return UserRoleUid.EMPLOYEE;
            case 'PowerCustomerSuccess':
                return UserRoleUid.CUSTOMER;
            default:
                return UserRoleUid.AUTHENTICATED;
        }
    }

    /**
     * Indicates whether the given SFDC User Profile name is for a Customer Success Manager (CSM) or a Technical Account Manager (TAM) user.
     * @param userProfileName the current user's SFDC User Profile name
     * @returns boolean
     */
    private isCsmOrTamUser(userProfileName: string): boolean {
        // Possible profiles are "GEN - Sales CSM" and "Customer Care TAM LEX".
        // We don't include the full name of the "GEN - Sales CSM" profile to avoid unicode character comparison errors with the "-" character.
        return userProfileName.includes('Sales CSM') || userProfileName.includes('Customer Care TAM LEX');
    }

    /**
     * Indicates whether the given SFDC User Profile name is for a Consultant user.
     * @param userProfileName the current user's SFDC User Profile name
     * @returns boolean
     */
    private isConsultant(userProfileName: string): boolean {
        return (
            userProfileName.includes('Consultant Portal Manager Custom') ||
            userProfileName.includes('Genesys Consultant Portal Access')
            // || userProfileName.includes('System Administrator')
        );
    }

    /** @returns true when the user is authenticated */
    get isAuthenticated$(): Observable<boolean> {
        return this._isAuthenticated.asObservable();
    }

    /** @returns user oauth claims */
    get claims$(): Observable<UserClaims> {
        return this._claims$.asObservable();
    }

    /** @returns the user first/last name as initials */
    get initials$(): Observable<string> {
        return this.claims$.pipe(
            map((claims) => {
                return (claims?.given_name?.slice(0, 1) || '') + (claims?.family_name?.slice(0, 1) || '');
            }),
        );
    }

    /** @returns Genesys product(s) the user has access to mapped as an array of GKN {@link Product} objects */
    get subscribedProducts$(): Observable<Product[]> {
        return this._subscribedProducts$.asObservable();
    }

    /** @returns all salesforce data for this user (made available via our REST integration, not actually every single salesforce field) */
    get sfdcUserDetail$(): Observable<KeplerSfdcUserDetail> {
        return this._sfdcUserDetail$.asObservable();
    }

    /** @returns shortcut for {@link sfdcUserDetail$} to get this user's salesforce contact ID. This can be considered a salesforce user's primary key */
    get sfdcContactId(): SalesforceContactId {
        return this._sfdcContactId;
    }

    /** @returns the user's GKN role, which is based on salesforce user type, profile name, and other possible information */
    get userRole$(): Observable<UserRoleUid> {
        return this._userRole$.asObservable();
    }

    /** @returns shortcut for {@link sfdcUserDetail$} to get this user's customer success manager, or null */
    get csmUser$(): Observable<KeplerSfdcUser | null> {
        return this.sfdcUserDetail$.pipe(map((userDetail) => userDetail?.CSM_User));
    }

    get advisorUser$(): Observable<KeplerSfdcUser | null> {
        return this.sfdcUserDetail$.pipe(map((userDetail) => userDetail?.Advisor_User));
    }

    get isEmployee$(): Observable<boolean> {
        return this._isEmployee$.asObservable();
    }

    get isCssEmployee$(): Observable<boolean> {
        return this._isCssEmployee$.asObservable();
    }

    /**
     * @deprecated Use {@link isEmployee$} instead
     * @returns true for employee users
     */
    // TODO Move into ngOnInit where _isEmployee.next() is called
    isEmployee(userRole: UserRoleUid, data?: KeplerSfdcUserDetail): boolean {
        return (
            userRole === UserRoleUid.EMPLOYEE ||
            userRole === UserRoleUid.CSM_TAM ||
            userRole === UserRoleUid.CONSULTANT ||
            data?.User_Groups?.includes('CSDTTesters') || // CSDT Employees
            this.isCssEmployee(data)
        );
    }

    /**
     * @returns true when this user is a Customer Success employee via the Executive VP assigned to the user.
     */
    private isCssEmployee(data?: KeplerSfdcUserDetail): boolean {
        return data?.User?.EVP__c === UserService.CSS_EVP_ID;
    }

    get cases$(): Observable<MyCase[]> {
        return this.sfdcUserDetail$.pipe(
            map((sfUserDetail) => {
                return (
                    sfUserDetail?.Cases?.map((caseRecord: KeplerSfdcCase) => ({
                        id: caseRecord.Id,
                        case: caseRecord.CaseNumber,
                        subject: caseRecord.Subject,
                        status: caseRecord.Status,
                        updatedDate: caseRecord.LastModifiedDate,
                    })) || []
                );
            }),
        );
    }
}
