import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@environments/environment';
import { OktaAuthStateService } from '@okta/okta-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subscription } from 'rxjs';
import { NavGroup } from '../../brain-tab-nav-group.interface';

@Component({
    selector: 'app-brain-tab-favorites',
    standalone: true,
    imports: [CommonModule, NgxSkeletonLoaderModule, RouterModule],
    templateUrl: './brain-tab-favorites.component.html',
    styleUrls: ['./brain-tab-favorites.component.css'],
})
export class BrainTabFavoritesComponent implements AfterViewInit, OnDestroy {
    @Input() group: NavGroup;
    @ViewChild('favList') favList: ElementRef;

    @Output() login = new EventEmitter<boolean>();
    gknUrl = environment.gkn.url;

    private sub: Subscription;

    constructor(public authStateService: OktaAuthStateService) {}

    ngAfterViewInit() {
        this.sub = this.group.favorites$.subscribe(() => {
            this.favList?.nativeElement?.classList.add('expanded');
        });
    }

    handleLogin(e: Event): void {
        e?.stopPropagation();
        this.login.emit();
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }
}
