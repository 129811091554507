import { CommonModule, DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, RouterModule } from '@angular/router';
import { LoginFormComponent, TrackByItem, VerticalDividerComponent } from '@components';
import { ProductUid, StaticFavoriteRoutePath, Theme, UserRoleUid } from '@enums';
import { environment } from '@environments/environment';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { SigninWithCredentialsOptions, UserClaims } from '@okta/okta-auth-js';
import {
    ConnectWithGenesysTile,
    FavoriteAttributes,
    FavoritesService,
    ProductService,
    UserService,
    connectWithGenesysTileById,
} from '@services';
import { FavoriteElementMeta } from '@types';
import { isPrideMonth, scrollToTopScrolling } from '@utils';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    Subscription,
    combineLatest,
    delay,
    filter,
    map,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { BrainTabNavGroupLinksComponent } from './brain-tab-nav-group-links/brain-tab-nav-group-links.component';
import { BrainTabNavGroupTitleComponent } from './brain-tab-nav-group-title/brain-tab-nav-group-title.component';
import { NAV_GROUPS } from './brain-tab-nav-group.data';
import { NavGroup, NavGroupLink } from './brain-tab-nav-group.interface';
import { BrainTabSearchBarComponent } from './brain-tab-search-bar/brain-tab-search-bar.component';
import { BrainTabSearchResultsComponent } from './brain-tab-search-results/brain-tab-search-results.component';

@Component({
    selector: 'app-brain-tab',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        BrainTabNavGroupLinksComponent,
        BrainTabNavGroupTitleComponent,
        BrainTabSearchBarComponent,
        BrainTabSearchResultsComponent,
        LoginFormComponent,
        NgxSkeletonLoaderModule,
        VerticalDividerComponent,
    ],
    templateUrl: './brain-tab.component.html',
    styleUrls: ['./brain-tab.component.css'],
})
export class BrainTabComponent implements OnInit, AfterViewInit, OnDestroy, TrackByItem<NavGroup> {
    private subs: Subscription[] = [];

    theme$: BehaviorSubject<Theme> = new BehaviorSubject(Theme.LIGHT);

    activeAccordion$: BehaviorSubject<ProductUid> = new BehaviorSubject(ProductUid.GENESYS_CLOUD_CX);

    @Input() theme?: Theme = Theme.LIGHT;
    @HostBinding('class.theme-light') isLightMode = true;
    @HostBinding('class.theme-dark') isDarkMode = false;
    @HostBinding('class.theme-navy') isNavyMode = false;

    gknUrl = environment.gkn.url;
    logoPath$: ReplaySubject<string> = new ReplaySubject(1);

    @Output() signInWithCredentialsEmitter = new EventEmitter<SigninWithCredentialsOptions>();
    @Output() signInWithOktaEmitter = new EventEmitter<void>();

    showSignInButton$ = new BehaviorSubject(true);
    showLoginForm$ = new BehaviorSubject(false);
    showSignOutButton$ = new BehaviorSubject(false);

    firstName$: Observable<string>;

    @ViewChild(BrainTabSearchBarComponent) searchBar: BrainTabSearchBarComponent;
    searchTerm$: BehaviorSubject<string> = new BehaviorSubject('');
    isSearchLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    showSearchResults$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    viewAllUrl$: Observable<string>;
    verticalColor: string;

    @ViewChild('nav') navRef: ElementRef;
    navGroups$: Observable<NavGroup[]>;

    constructor(
        private activatedRoute: ActivatedRoute,
        public authStateService: OktaAuthStateService,
        private cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private favoritesService: FavoritesService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
        private productService: ProductService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.setTheme(this.theme);

        this.subs.push(
            this.activatedRoute.queryParams
                .pipe(
                    tap((queryParams: Params) => {
                        const theme = queryParams['theme'];
                        if (theme) {
                            this.setTheme(theme);
                        }
                    }),
                )
                .subscribe(),
        );

        this.subs.push(
            this.searchTerm$
                .pipe(
                    // artificial delay to allow *ngIf to render <app-brain-tab-search-results>
                    // element. It must be available on the DOM for Coveo.$$ to work properly.
                    delay(1250),
                    tap((searchTerm) => {
                        try {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            const searchElement = Coveo.$$(this.document as unknown as Coveo.Dom);
                            const searchBox = searchElement?.find('.CoveoSearchbox');
                            if (searchBox) {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                const searchBoxInstance = Coveo.get(searchBox) as Coveo.Searchbox;

                                searchBoxInstance?.searchbox?.setText(searchTerm);
                                searchBoxInstance?.searchButton?.click();

                                // no  need to update the product facet filter for brain tab
                                // at this point, it has already been set when the search
                                // page was iniialized, and we clear results when the product
                                // changes, by design.
                            }
                        } catch (err) {
                            // always turn off the loading spinner in cases of error
                            console.error(err);
                        }
                        this.isSearchLoading$.next(false);
                    }),
                )
                .subscribe(),
        );

        this.viewAllUrl$ = this.searchTerm$.asObservable().pipe(
            switchMap((searchTerm) => combineLatest([of(searchTerm), this.activeAccordion$])),
            map(([searchTerm, activeAccordion]) => {
                const temp = new URL(`${this.gknUrl}/search`);
                temp.searchParams.set('product', activeAccordion);

                let hashObj = {};

                // make sure a hash is present before trying to merge the existing hash with the search term.
                if (temp.hash && temp.hash != '') {
                    hashObj = temp.hash.split('&').reduce((res, item) => {
                        const pair = item.split('=');
                        res[pair[0]] = pair[1];
                        return res;
                    }, {});
                }
                hashObj['q'] = searchTerm;

                temp.hash = Object.keys(hashObj)
                    .map((key) => `${key}=${hashObj[key]}`)
                    .join('&');

                return temp.toString();
            }),
        );

        const claims$ = this.userService.claims$;
        this.firstName$ = claims$.pipe(map((claims: UserClaims) => claims?.given_name || ''));
        this.subs.push(
            // notifies the widget button on the parent site DOM to turn ON user initials
            claims$
                .pipe(
                    map((claims: UserClaims) => {
                        return (claims?.given_name?.slice(0, 1) || '') + (claims?.family_name?.slice(0, 1) || '');
                    }),
                    tap((initials) => {
                        this.postMessageToIframe('login', initials);
                    }),
                )
                .subscribe(),
            // notifies the widget button on the parent site DOM to turn OFF user initials and show brain tab icon
            this.authStateService.authState$
                .pipe(
                    filter((state) => !state.isAuthenticated),
                    tap(() => {
                        this.postMessageToIframe('logout');
                    }),
                )
                .subscribe(),
        );

        this.navGroups$ = this.userService.userRole$.pipe(
            map((userRole: UserRoleUid) => {
                this.showSignInButton$.next(false);
                this.showLoginForm$.next(false);
                this.showSignOutButton$.next(true);
                this.cdr.markForCheck();

                return this.filterGroupLinksByForUserRole(userRole);
            }),
        );
    }

    /**
     * Listens for messages posted by the parent window and reacts accordingly.
     * This is known as cross-document messaging. https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage.
     * The complete key must match here and the braintab-core.js in csdt-assets.
     * The `gcsdtbt-data` prefix is necessary because the messaging channel is global.
     * This enables braintab-core.js to send us data during runtime.
     */
    @HostListener('window:message', ['$event'])
    onMessage(event: any) {
        if ((typeof event.data === 'string' || event.data instanceof String) && event.data.startsWith('gcsdtbt-data')) {
            const message = event.data.split('::');
            if (message.length > 1) {
                const key = message[1];
                const data = message[2];

                switch (key) {
                    case 'theme':
                        this.setTheme(data);
                        break;
                    case 'product':
                        this.setProductSelection(data as ProductUid);
                        break;
                    case 'login':
                    // fall-through
                    case 'logout':
                        /*
                         * catch and no-op to avoid a console.error.
                         * know-client sends "login/logout" but does not receive it.
                         * brain tab should not be allowed to trigger login/logout.
                         */
                        break;
                    default:
                        console.error(`Unhandled gcsdtbt data "${key}"`);
                }
            }
        }
    }

    /**
     * Posts a message to the top (parent) window of the Brain Tab iframe.
     * This is known as cross-document messaging. https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage.
     * The complete key must match here and the braintab-core.js in csdt-assets.
     * The `gcsdtbt-data` prefix is necessary because the messaging channel is global.
     * This enables braintab-core.js to listen specifically for our events/data.
     *
     * @param key message key
     * @param data optional data for the message. Recommended: primitive string over serialized JSON.
     */
    private postMessageToIframe(key: string, data?: string) {
        const message = ['gcsdtbt-data', key];
        const delimiter = '::';

        if (data) {
            message.push(data);
        }

        window.top.postMessage(message.join(delimiter), '*');
    }

    private favorites$(group: NavGroup): Observable<FavoriteElementMeta[]> {
        return this.favoritesService.favorites$.pipe(
            map((favorites: FavoriteAttributes[]) => favorites.filter((fav) => fav.productId === group.uid)),
            switchMap((favorites: FavoriteAttributes[]) => {
                const arr = favorites
                    .filter((favorite) => !!favorite.favoriteId) // KNOW-2313 Although we no longer assert against this favorite being in favorite-id.enum.ts, we still need to ensure the favorite ID is defined
                    .map((favorite: FavoriteAttributes) => {
                        const obs: Observable<FavoriteElementMeta> = favorite.favoriteId.startsWith('owtgi')
                            ? of(connectWithGenesysTileById(favorite.favoriteId)).pipe(
                                  filter((tile) => !!tile), // KNOW-1800 Handle an old favorite ID that has since been removed from Strapi
                                  map((tile: ConnectWithGenesysTile) => ({
                                      id: favorite.favoriteId,
                                      label: tile.title,
                                      urlPath: tile.url,
                                      attributes: favorite,
                                      // brain tab does not show thumbnails
                                  })),
                              )
                            : of({
                                  id: favorite.favoriteId,
                                  label: this.favoritesService.mapToLabel(favorite.favoriteId, group),
                                  urlPath: '/' + StaticFavoriteRoutePath[favorite.favoriteId],
                                  attributes: favorite,
                                  // brain tab does not show thumbnails
                              });
                        return obs;
                    });
                return arr.length > 0 ? combineLatest(arr) : of([]);
            }),
        );
    }

    private filterGroupLinksByForUserRole(userRoleUid: UserRoleUid) {
        // Tip: Hard code to UserRoleUid.CONSULTANT to see all possible nav links

        const groupsForUserRole: NavGroup[] = NAV_GROUPS.map((group: NavGroup) => ({
            ...group,
            links: group.links
                .filter((link: NavGroupLink) => link.userRoles.includes(userRoleUid))
                .sort((a, b) => a.title.localeCompare(b.title)), // sort links alphabetical
            favorites$: this.favorites$(group),
        })).sort((a, b) => a.rank - b.rank); // sort products nav groups by rank
        return groupsForUserRole;
    }

    /**
     * Sets the product in both the GKN and Brain Tab. Cannot collapses all accordions.
     * @param product to set
     */
    private setProductSelection(product: ProductUid): void {
        this.activeAccordion$.next(product); // open the other product accordion
        this.handleClearAllResults();
    }

    /**
     * Sets the active accordion in Brain Tab and the product in GKN. Can collapses all accordions.
     * @param product to set
     */
    setActiveAccordion(product: ProductUid) {
        this.productService.setSelectedProduct(product);
        if (this.activeAccordion$.value === product) {
            this.activeAccordion$.next(null); // close the accordion without opening another
        } else {
            this.activeAccordion$.next(product); // open the other product accordion
        }
        this.handleClearAllResults();
    }

    private setTheme(theme: Theme) {
        this.theme$.next(theme);
        let logoPath = isPrideMonth() ? '/assets/images/gkn-pride-logo.svg' : '/assets/images/gkn-logo-light.svg';

        switch (theme) {
            case Theme.NAVY:
                this.isDarkMode = false;
                this.isLightMode = false;
                this.isNavyMode = true;
                logoPath = '/assets/images/gkn-logo-dark.svg';
                break;
            case Theme.DARK:
                this.isDarkMode = true;
                this.isLightMode = false;
                this.isNavyMode = false;
                logoPath = '/assets/images/gkn-logo-dark.svg';
                break;
            case Theme.LIGHT:
            //fall-through
            default:
                this.isDarkMode = false;
                this.isLightMode = true;
                this.isNavyMode = false;
                logoPath = '/assets/images/gkn-logo-light.svg';
        }

        this.logoPath$.next(logoPath);

        this.cdr.markForCheck();
    }

    ngAfterViewInit(): void {
        if (this.isLightMode) {
            this.navRef?.nativeElement.classList.add('light');
        } else {
            this.navRef?.nativeElement.classList.remove('light');
        }

        if (this.isDarkMode) {
            this.navRef?.nativeElement.classList.add('dark');
        } else {
            this.navRef?.nativeElement.classList.remove('dark');
        }

        if (this.isNavyMode) {
            this.navRef?.nativeElement.classList.add('navy');
        } else {
            this.navRef?.nativeElement.classList.remove('navy');
        }
    }

    enableLoginForm(e?: Event): void {
        e?.stopPropagation();
        this.showSignInButton$.next(false);
        this.showLoginForm$.next(true);
        this.showSignOutButton$.next(false);
        this.cdr.markForCheck();
    }

    /**
     * Redirects a user to the Okta hosted login page for signing in
     * The OktaCallbackComponent setup in app-routing.module.ts handles
     * all the token management on successful authentication.
     *
     * @returns a void Promise
     */
    async signInWithOkta(): Promise<void> {
        return this.oktaAuth.signInWithRedirect();
    }

    cancelSignIn(e?: Event) {
        e?.stopPropagation();
        this.showSignInButton$.next(true);
        this.showLoginForm$.next(false);
        this.showSignOutButton$.next(false);
        this.cdr.markForCheck();
    }

    async signOut(e?: Event): Promise<void> {
        e?.stopPropagation();
        await this.oktaAuth.signOut();
    }

    handleClearSearchTerm(): void {
        this.searchBar?.reset();
        this.handleSubmitSearch('');
    }

    /**
     * Clears the search term and reset the search bar to default.
     * @param e DOM event
     */
    handleClearAllResults(e?: Event): void {
        e?.stopPropagation();
        this.searchBar?.reset();
        this.showSearchResults$.next(false);
    }

    /**
     * Submits a new search term by filling the Coveo search box,
     * then clicking the search button to send the query to the Coveo API.
     */
    handleSubmitSearch(searchTerm: string): void {
        this.showSearchResults$.next(true);
        this.isSearchLoading$.next(true);
        this.searchTerm$.next(searchTerm);
        scrollToTopScrolling();
    }

    trackByItem(index: number, item: NavGroup): NonNullable<number | string> {
        return item.uid;
    }

    ngOnDestroy(): void {
        this.subs?.forEach((sub) => sub?.unsubscribe());
    }
}
