import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@environments/environment';
import { UserService } from '@services';
import { firstValueFrom, Subscription, tap } from 'rxjs';
import { ProductDisplayName, ProductUid } from 'src/app/shared/enums';

@Component({
    selector: 'app-brain-tab-search-bar',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    templateUrl: './brain-tab-search-bar.component.html',
    styleUrls: ['./brain-tab-search-bar.component.css'],
})
export class BrainTabSearchBarComponent implements OnInit, OnChanges, OnDestroy {
    @Input() product: ProductUid;
    placeholder: string;

    @Output() searchClick = new EventEmitter<string>();
    @Output() clearClick = new EventEmitter<void>();
    searchTerm = new FormControl('');
    private sub!: Subscription;

    private userService = inject(UserService);

    ngOnInit(): void {
        this.setPlaceholder(this.product);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setPlaceholder(changes.product.currentValue);
    }

    private setPlaceholder(val: string) {
        let placeholder = 'Search';
        const product = ProductDisplayName[val];
        if (product) {
            placeholder += ' ' + product;
        } else {
            placeholder += ' Genesys';
        }
        this.placeholder = placeholder;
    }

    /**
     * Emits the active search term text.
     * @param event click event from html template
     */
    handleSearch(event: Event): void {
        event?.stopPropagation();

        const searchTerm = this.searchTerm.value;
        if (!searchTerm || searchTerm === '') {
            // Do not submit a search for an empty field
            return;
        } else {
            this.sub = this.userService.isAuthenticated$
                .pipe(
                    tap((isAuthenticated) => {
                        if (isAuthenticated) {
                            const cases$ = this.userService.cases$;
                            firstValueFrom(cases$).then((userDetail) => {
                                const found = userDetail?.find((item) => item.case == searchTerm);
                                if (found) {
                                    window.open(`${environment.mySupport.url}?case=${found.case}`, '_blank');
                                } else {
                                    this.searchClick.emit(searchTerm);
                                }
                            });
                        } else {
                            this.searchClick.emit(searchTerm);
                        }
                    }),
                )
                .subscribe();
        }
    }

    /**
     * Emits an empty event for clearing the active search term text.
     */
    handleClear(): void {
        this.reset();
        this.clearClick.emit(); // emit "nothing". do not emit empty string.
    }

    reset(): void {
        this.searchTerm.setValue('');
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }
}
