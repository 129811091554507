import { KeplerCoveoCommonSourceName, KeplerCoveoSource } from './kepler-coveo.enum';
import { KeplerCoveoRecommendation, KeplerCoveoSuggestion } from './kepler-coveo.interface';

export const mockSuggestions: KeplerCoveoSuggestion[] = [
    {
        objectId: '1',
        expression: 'foo',
    },
    {
        objectId: '2',
        expression: 'bar',
    },
];

export const mockRecommendations: KeplerCoveoRecommendation[] = [
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            commonsourcename: KeplerCoveoCommonSourceName.KNOWLEDGE_BASE,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            commonsourcename: KeplerCoveoCommonSourceName.DOCUMENTATION,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: true,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            commonsourcename: KeplerCoveoCommonSourceName.TRAINING,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            commonsourcename: KeplerCoveoCommonSourceName.VIDEO,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            source: KeplerCoveoSource.GENESYS_ONLINE_COMMUNITY_HL,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            source: KeplerCoveoSource.COMMUNITY_YOUTUBE,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            source: KeplerCoveoSource.COMMUNITY_YOUTUBE_1,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            source: KeplerCoveoSource.COMMUNITY_YOUTUBE_2,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            source: KeplerCoveoSource.GENESYS_BLOG,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            source: KeplerCoveoSource.GENESYS_BLOG_2,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            source: KeplerCoveoSource.DEV_CENTER,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
    {
        title: 'foo',
        uri: 'https://example.com/',
        excerpt: 'bar',
        raw: {
            source: KeplerCoveoSource.DEV_CENTER_2,
        },
        thumbnailIcon: 'thumbnail.png',
        subtitleIcon: 'subtitle.png',
        isLoadingInFrame: false,
        dataAnalytics: ['foo', 'bar'],
    },
];
