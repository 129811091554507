import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BrainTabComponent } from './brain-tab/brain-tab.component';

@Component({
    selector: 'app-home-component',
    standalone: true,
    imports: [BrainTabComponent],
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    theme: 'light' | 'dark' | 'navy';

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        // this global variable is declared via the csdt-assets
        // snippet in index.html in client applications. this
        // variable does not exist when running this application standalone.
        const braintab = window['braintab'];
        if (braintab) {
            this.theme = braintab.theme ?? 'light';
            this.cdr.markForCheck();
        }
    }
}
