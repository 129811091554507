<button (click)="handleToggle($event)">
    <h2>{{ group.displayName }}</h2>
    <img
        [src]="caretIconPath$ | async"
        class="caret-arrow"
        [ngClass]="{ active: isActive }"
        alt="Toggle"
        width="16px"
        height="16px" />
</button>
