import { Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { HomeComponent } from './core/home/home.component';

export const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
    },
    {
        path: 'login/callback',
        component: OktaCallbackComponent,
    },
    {
        // This route should always be the very last route.
        // It will catch any non-matching endpoints and redirect to /home.
        path: '**',
        pathMatch: 'full',
        component: HomeComponent,
    },
];

/**
 * GKN page routes, e.g. https://know.genesys.com/community
 */
export enum GknRoutePath {
    COMMUNITY = 'community',
    EDUCATION_AND_TRAINING = 'education-and-training',
    GET_INVOLVED = 'get-involved',
    GKN_STORIES = 'gkn-stories',
    HOME = 'home',
    HOME_ARCHIVES = 'home/archives',
    PROFILE = 'profile',
    RESOURCES = 'resources',
    SUPPORT = 'support',
    SEARCH = 'search',
}
