import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TrackByItem } from 'src/app/shared/components';
import { NavGroup, NavGroupLink } from '../brain-tab-nav-group.interface';
import { BrainTabFavoritesComponent } from './brain-tab-favorites/brain-tab-favorites.component';

@Component({
    selector: 'app-brain-tab-nav-group-links',
    standalone: true,
    imports: [CommonModule, BrainTabFavoritesComponent, NgxSkeletonLoaderModule],
    templateUrl: './brain-tab-nav-group-links.component.html',
    styleUrls: ['./brain-tab-nav-group-links.component.css'],
})
export class BrainTabNavGroupLinksComponent implements OnChanges, TrackByItem<NavGroupLink> {
    @Input() group: NavGroup;
    @Input() isActive: boolean;
    @Output() login = new EventEmitter<void>();
    maxHeight: string;

    constructor(public authStateService: OktaAuthStateService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.group?.currentValue) {
            this.maxHeight =
                changes.group.currentValue.links.length <= 6
                    ? '100px'
                    : changes.group.currentValue.links.length <= 8
                      ? '120px'
                      : changes.group.currentValue.links.length <= 10
                        ? '155px'
                        : changes.group.currentValue.links.length <= 12
                          ? '180px'
                          : '200px';
        }
    }

    handleLogin(): void {
        this.login.emit();
    }

    trackByItem(_index: number, item: NavGroupLink): NonNullable<number | string> {
        return item.url;
    }
}
