import {
    CommunitySectionLabel,
    CxEvolutionFilterLabel,
    DevCenterFilterLabel,
    EduTrainingBadgesFilterLabel,
    EduTrainingSectionLabel,
    FavoriteId,
    GetInvolvedSectionLabel,
    GetStartedFilterLabel,
    HomeSectionLabel,
    JustForMeSectionLabel,
    NewsFromGenesysFilterLabel,
    ProductHelpFilterLabel,
    ResourcesSectionLabel,
    SupportSectionLabel,
} from '@enums';

import { Observable, combineLatest, concatMap, map } from 'rxjs';
import { Product } from '../product';
import { FavoriteAttributes, FavoritesDbResponse, UserCredentials } from './types';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { UserService } from '@services';

@Injectable({ providedIn: 'root' })
export class FavoritesService {
    private readonly favoritesApiUrl = environment.api.resourceServer.favoritesUrl;

    constructor(
        private userService: UserService,
        private http: HttpClient,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    ) {}

    /** Get user salesforce Id */
    get userSalesForceId$() {
        // Alternative approach is to lett the server add its own unique ID
        return this.userService.sfdcUserDetail$.pipe(map((userSfdcRes) => userSfdcRes?.User?.Id));
    }

    /** Get user credentials required to make API requests */
    private get userCredential$(): Observable<UserCredentials> {
        return combineLatest([this.userSalesForceId$, this.userService.claims$]).pipe(
            map(([userId, claims]) => {
                return {
                    headers: {
                        Authorization: `Bearer ${this.oktaAuth.getAccessToken()}`,
                    },
                    userId: userId,
                    email: claims?.email,
                };
            }),
        );
    }

    /** Observable that emits(once) the updated list of favorites. */
    get favorites$(): Observable<FavoriteAttributes[]> {
        return this.userCredential$.pipe(
            concatMap(({ headers, email, userId }) => {
                const params = { email, userId };

                return this.http.get<FavoritesDbResponse>(this.favoritesApiUrl, { headers, params });
            }),
            map(({ favorites }) => favorites),
        );
    }

    /**
     * Maps the given favorite ID to a human-readable label/display name. This
     * function should only be used for favorite IDs that represent static content.
     * Dynamic content from Strapi should use the label from the API response.
     *
     * @param favoriteId to map
     * @param selectedProduct the currently selected product. Some favorite IDs map to a label that is prefixed with the product label.
     * @returns a label (display name) for the given favorite
     * @throws when the given favorite is not mapped to any label, or the given favorite represents an Other Way To Get Involved tile (Know CMS)ß
     */
    mapToLabel(favoriteId: FavoriteId, selectedProduct?: Product): string {
        switch (favoriteId) {
            case FavoriteId.APP_FOUNDRY:
                return ResourcesSectionLabel.APP_FOUNDRY;
            case FavoriteId.ARCHIVES:
                return HomeSectionLabel.ARCHIVES;
            case FavoriteId.BEYOND:
                return EduTrainingSectionLabel.BEYOND_TRAINING;
            // case FavoriteId.CS_ACADEMY:
            //     return EduTrainingSectionLabel.CS_ACADEMY;
            case FavoriteId.COMMUNITY_QA_SHOW:
                return CommunitySectionLabel.QA_SHOW;
            case FavoriteId.CUSTOMER_SUCCESS:
                return ResourcesSectionLabel.CUSTOMER_SUCCESS;
            case FavoriteId.CX_EVOLUTION:
                return ResourcesSectionLabel.CX_EVOLUTION;
            case FavoriteId.DEVELOPER_CENTER_POPULAR:
                return ResourcesSectionLabel.DEV_CENTER;
            case FavoriteId.DEVELOPER_PORTAL_PURECONNECT:
                return ResourcesSectionLabel.DEV_PORTAL_PURECONNECT;
            case FavoriteId.EXPERT_APPS:
                return ResourcesSectionLabel.EXPERT_APPS;
            case FavoriteId.GET_STARTED:
                return ResourcesSectionLabel.GET_STARTED;
            case FavoriteId.MARKETING_EVENTS:
                return GetInvolvedSectionLabel.MARKETING_EVENTS;
            case FavoriteId.LATEST_DISCUSSIONS:
                return CommunitySectionLabel.LATEST_DISCUSSIONS;
            case FavoriteId.PRODUCT_FUNDAMENTALS:
                return `${selectedProduct?.displayName} ${HomeSectionLabel.FUNDAMENTALS}`.trim();
            case FavoriteId.PRODUCT_SUPPORT_CENTER:
                return SupportSectionLabel.PRODUCT_SUPPORT_CENTER;
            case FavoriteId.RELATED_COMMUNITIES:
                return CommunitySectionLabel.RELATED_COMMUNITIES;
            case FavoriteId.SUPPORT_CASES:
                return SupportSectionLabel.MY_CASES;
            case FavoriteId.MY_CONTACTS:
                return SupportSectionLabel.MY_CONTACTS;
            case FavoriteId.RECOMMENDATIONS:
                return SupportSectionLabel.RECOMMENDATIONS;
            case FavoriteId.EDU_EVENTS_AND_WEBINARS:
                return EduTrainingSectionLabel.EDU_EVENTS_AND_WEBINARS;
            case FavoriteId.NEWS_FROM_GENESYS_ALL:
                return `${HomeSectionLabel.NEWS_FROM_GENESYS} — ${NewsFromGenesysFilterLabel.ALL}`;
            case FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS:
                return `${HomeSectionLabel.NEWS_FROM_GENESYS} — ${NewsFromGenesysFilterLabel.ANNOUNCEMENTS}`;
            case FavoriteId.NEWS_FROM_GENESYS_BLOG:
                return `${HomeSectionLabel.NEWS_FROM_GENESYS} — ${NewsFromGenesysFilterLabel.BLOG}`;
            case FavoriteId.NEWS_FROM_GENESYS_SOCIAL:
                return `${HomeSectionLabel.NEWS_FROM_GENESYS} — ${NewsFromGenesysFilterLabel.SOCIAL}`;
            case FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.MY_PERSONALIZED_VIDEO}`;
            case FavoriteId.GET_STARTED_STARTER_KIT:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.STARTER_KIT}`;
            case FavoriteId.GET_STARTED_ONBOARDING:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.NEW_CUSTOMER_ONBOARDING}`;
            case FavoriteId.GET_STARTED_SHORT_TRACKS:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.SHORT_TRACKS}`;
            case FavoriteId.GET_STARTED_USEFUL_LINKS:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.USEFUL_LINKS}`;
            case FavoriteId.GET_STARTED_TIPS_AND_TOOLS:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.TIPS_AND_TOOLS}`;
            case FavoriteId.CX_EVOLUTION_GET_STARTED:
                return `${ResourcesSectionLabel.CX_EVOLUTION} — ${CxEvolutionFilterLabel.GET_STARTED}`;
            case FavoriteId.CX_EVOLUTION_CONNECT:
                return `${ResourcesSectionLabel.CX_EVOLUTION} — ${CxEvolutionFilterLabel.CONNECT}`;
            case FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS:
                return `${ResourcesSectionLabel.CX_EVOLUTION} — ${CxEvolutionFilterLabel.LEARN_FROM_OTHERS}`;
            case FavoriteId.PRODUCT_HELP_ARTICLES:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP} — ${ProductHelpFilterLabel.ARTICLES}`;
            case FavoriteId.PRODUCT_HELP_VIDEOS:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP} — ${ProductHelpFilterLabel.VIDEOS}`;
            case FavoriteId.PRODUCT_HELP_USE_CASES:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP} — ${ProductHelpFilterLabel.USE_CASES}`;
            case FavoriteId.PRODUCT_HELP_DOCUMENTATION:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP} — ${ProductHelpFilterLabel.DOCUMENTATION}`;
            case FavoriteId.DEVELOPER_CENTER_TOOLS:
                return `${ResourcesSectionLabel.DEV_CENTER} — ${DevCenterFilterLabel.TOOLS}`;
            case FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS:
                return `${ResourcesSectionLabel.DEV_CENTER} — ${DevCenterFilterLabel.POPULAR_TOPICS}`;
            case FavoriteId.DEVELOPER_CENTER_BLOG:
                return `${ResourcesSectionLabel.DEV_CENTER} — ${DevCenterFilterLabel.BLOGS}`;
            case FavoriteId.CERTIFICATIONS_MY_AWARDS:
                return `${EduTrainingSectionLabel.CERTIFICATIONS_AND_BADGES} — ${EduTrainingBadgesFilterLabel.MY_AWARDS}`;
            case FavoriteId.CERTIFICATIONS_GET_CERTIFIED:
                return `${EduTrainingSectionLabel.CERTIFICATIONS_AND_BADGES} — ${EduTrainingBadgesFilterLabel.GET_CERTIFIED}`;
            /*
             * Some favorite IDs cannot be favorited but this function's intent is to
             * never actually get to the default case (covered by existing unit tests),
             * so return _any_ non-empty string
             */
            case FavoriteId.JUST_FOR_ME:
                return HomeSectionLabel.JUST_FOR_ME;
            case FavoriteId.CONNECT_WITH_GENESYS:
                return GetInvolvedSectionLabel.CONNECT_WITH_GENESYS;
            case FavoriteId.ADDITIONAL_SITES:
                return JustForMeSectionLabel.ADDITIONAL_SITES;
            case FavoriteId.MY_FAVORITES:
                return JustForMeSectionLabel.MY_FAVORITES;
            //fall-through
            default:
                console.error(`Label not defined for static favorite section: ${favoriteId}`);
                return favoriteId;
        }
    }
}
