/**
 * Enumerates static UI that can be favorited. Dynamic favorites from the CMS are not included here.
 *
 * Updating the values will break existing favorites (backwards compatibility).
 *
 * Entries are purposefully alphabetical to allow for deterministic
 * usage of functions like Object.values()
 */
export enum FavoriteId {
    APP_FOUNDRY = 'app-foundry',
    ARCHIVES = 'archives',
    BEYOND = 'genesys-beyond',
    /** contains temporary fix, check {@link BadgesComponent.toggleFilterToFavoriteItem} for more details. */
    COMMUNITY_QA_SHOW = 'community-qa-show',
    CUSTOMER_SUCCESS = 'customer-success',
    CX_EVOLUTION = 'cx-evolution',
    /** Dev center for both GC CX and GC MCX */
    /** @deprecated do not use -- was used for Dev Center Genesys Engage On-prem, which has been permanently taken offline. */
    DEVELOPER_CENTER_POPULAR = 'developer-center-popular',
    /** Dev center for Pureconnect. Do not change string value. It remains "-popular" for backward compatibility. */
    DEVELOPER_PORTAL_PURECONNECT = 'developer-portal-popular',
    EXPERT_APPS = 'expert-apps',
    GET_STARTED = 'get-started',
    MARKETING_EVENTS = 'marketing-events',
    LATEST_DISCUSSIONS = 'latest-discussions',
    PRODUCT_FUNDAMENTALS = 'product-fundamentals',
    RELATED_COMMUNITIES = 'related-communities',
    PRODUCT_SUPPORT_CENTER = 'product-support-center',
    SUPPORT_CASES = 'support-cases',

    EDU_EVENTS_AND_WEBINARS = 'webinars',
    MY_CONTACTS = 'my-contacts',
    CONNECT_WITH_GENESYS = 'connect-with-genesys',

    NEWS_FROM_GENESYS_ALL = 'news-from-genesys-all',
    NEWS_FROM_GENESYS_ANNOUNCEMENTS = 'news-from-genesys-announcements',
    NEWS_FROM_GENESYS_BLOG = 'news-from-genesys-blog',
    NEWS_FROM_GENESYS_SOCIAL = 'news-from-genesys-social',

    GET_STARTED_MY_PERSONALIZED_VIDEO = 'get-started-my-personalized-video',
    GET_STARTED_STARTER_KIT = 'get-started-starter-kit',
    GET_STARTED_ONBOARDING = 'get-started-onboarding',
    GET_STARTED_SHORT_TRACKS = 'get-started-short-tracks',
    GET_STARTED_USEFUL_LINKS = 'get-started-useful-links',
    GET_STARTED_TIPS_AND_TOOLS = 'get-started-tips-and-tools',

    CERTIFICATIONS_MY_AWARDS = 'certifications-my-awards',
    CERTIFICATIONS_GET_CERTIFIED = 'certifications-get-certified',

    CX_EVOLUTION_GET_STARTED = 'cx-evolution-get-started',
    CX_EVOLUTION_CONNECT = 'cx-evolution-connect',
    CX_EVOLUTION_LEARN_FROM_OTHERS = 'cx-evolution-learn-from-others',

    PRODUCT_HELP_ARTICLES = 'product-help-articles',
    PRODUCT_HELP_VIDEOS = 'product-help-videos',
    PRODUCT_HELP_USE_CASES = 'product-help-use-cases',
    PRODUCT_HELP_DOCUMENTATION = 'product-help-documentation',

    RECOMMENDATIONS = 'recommendations',
    DEVELOPER_CENTER_TOOLS = 'developer-center-tools',
    DEVELOPER_CENTER_POPULAR_TOPICS = 'developer-center-popular-topics',
    DEVELOPER_CENTER_BLOG = 'developer-center-blog',

    OWTGI_WRITE_A_REVIEW = 'owtgi-write-a-review',
    OWTGI_CUSTOMER_MONTHLY_NEWSLETTER = 'owtgi-customer-monthly-newsletter',
    OWTGI_SUPER_HUMAN_SERVICE = 'owtgi-super-human-service',
    OWTGI_PRODUCT_IDEAS_LAB = 'owtgi-product-ideas-lab',
    OWTGI_GENESYS_USER_GROUPS = 'owtgi-genesys-user-groups',
    OWTGI_COLLABORATE_WITH_OUR_UX_TEAM = 'owtgi-collaborate-with-our-ux-team',
    OWTGI_DEVELOPER_NEWSLETTER = 'owtgi-developer-newsletter',
    OWTGI_ADVOCACY_PAYS = 'owtgi-advocacy-pays',

    // "Dummy" favorites. Required in order to make filtering on "Just For Me" section work
    JUST_FOR_ME = 'just-for-me',
    ADDITIONAL_SITES = 'additional-sites',
    MY_FAVORITES = 'my-favorites',
}

/**
 * Maps a favorite ID for _static_ content to a GKN route path.
 * Dynamic content from the CMS is not included in this mapping.
 */
export const StaticFavoriteRoutePath: { [key in FavoriteId]?: string } = {
    [FavoriteId.APP_FOUNDRY]: 'resources',
    [FavoriteId.ARCHIVES]: 'home/archives',
    [FavoriteId.BEYOND]: 'education-and-training',
    [FavoriteId.COMMUNITY_QA_SHOW]: 'community',
    [FavoriteId.DEVELOPER_CENTER_POPULAR]: 'resources',
    [FavoriteId.EXPERT_APPS]: 'resources',
    [FavoriteId.CUSTOMER_SUCCESS]: 'resources',
    [FavoriteId.DEVELOPER_PORTAL_PURECONNECT]: 'resources',
    [FavoriteId.GET_STARTED]: 'resources',
    [FavoriteId.CX_EVOLUTION]: 'resources',
    [FavoriteId.MARKETING_EVENTS]: 'get-involved',
    [FavoriteId.LATEST_DISCUSSIONS]: 'community',
    [FavoriteId.PRODUCT_FUNDAMENTALS]: 'home',
    [FavoriteId.RELATED_COMMUNITIES]: 'community',
    [FavoriteId.PRODUCT_SUPPORT_CENTER]: 'support',
    [FavoriteId.SUPPORT_CASES]: 'support',
    [FavoriteId.EDU_EVENTS_AND_WEBINARS]: 'education-and-training',
    [FavoriteId.MY_CONTACTS]: 'support',
    [FavoriteId.RECOMMENDATIONS]: 'support',

    [FavoriteId.NEWS_FROM_GENESYS_ALL]: 'home',
    [FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS]: 'home',
    [FavoriteId.NEWS_FROM_GENESYS_BLOG]: 'home',
    [FavoriteId.NEWS_FROM_GENESYS_SOCIAL]: 'home',

    [FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO]: 'resources',
    [FavoriteId.GET_STARTED_STARTER_KIT]: 'resources',
    [FavoriteId.GET_STARTED_ONBOARDING]: 'resources',
    [FavoriteId.GET_STARTED_SHORT_TRACKS]: 'resources',
    [FavoriteId.GET_STARTED_USEFUL_LINKS]: 'resources',
    [FavoriteId.GET_STARTED_TIPS_AND_TOOLS]: 'resources',

    [FavoriteId.CERTIFICATIONS_MY_AWARDS]: 'education-and-training',
    [FavoriteId.CERTIFICATIONS_GET_CERTIFIED]: 'education-and-training',

    [FavoriteId.CX_EVOLUTION_GET_STARTED]: 'resources',
    [FavoriteId.CX_EVOLUTION_CONNECT]: 'resources',
    [FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS]: 'resources',

    [FavoriteId.PRODUCT_HELP_ARTICLES]: 'resources',
    [FavoriteId.PRODUCT_HELP_VIDEOS]: 'resources',
    [FavoriteId.PRODUCT_HELP_USE_CASES]: 'resources',
    [FavoriteId.PRODUCT_HELP_DOCUMENTATION]: 'resources',

    [FavoriteId.DEVELOPER_CENTER_TOOLS]: 'resources',
    [FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS]: 'resources',
    [FavoriteId.DEVELOPER_CENTER_BLOG]: 'resources',

    [FavoriteId.OWTGI_WRITE_A_REVIEW]: 'get-involved',
    [FavoriteId.OWTGI_CUSTOMER_MONTHLY_NEWSLETTER]: 'get-involved',
    [FavoriteId.OWTGI_SUPER_HUMAN_SERVICE]: 'get-involved',
    [FavoriteId.OWTGI_PRODUCT_IDEAS_LAB]: 'get-involved',
    [FavoriteId.OWTGI_GENESYS_USER_GROUPS]: 'get-involved',
    [FavoriteId.OWTGI_COLLABORATE_WITH_OUR_UX_TEAM]: 'get-involved',
    [FavoriteId.OWTGI_DEVELOPER_NEWSLETTER]: 'get-involved',
    [FavoriteId.OWTGI_ADVOCACY_PAYS]: 'get-involved',
};
