/**
 * Custom Coveo field "commonsourcename" setup in source mappings. Used in both Coveo sandbox and prod.
 */
export enum KeplerCoveoCommonSourceName {
    KNOWLEDGE_BASE = 'Knowledge Base',
    DOCUMENTATION = 'Documentation',
    TRAINING = 'Training',
    VIDEO = 'Video',
}

/**
 * Coveo sources of data. Used in both Coveo sandbox and prod.
 */
export enum KeplerCoveoSource {
    GENESYS_ONLINE_COMMUNITY_HL = 'GenesysOnlineCommunityHL',
    COMMUNITY_YOUTUBE = 'CommunityYouTube',
    COMMUNITY_YOUTUBE_1 = 'CommunityYoutube1',
    COMMUNITY_YOUTUBE_2 = 'CommunityYoutube2',
    GENESYS_BLOG = 'Genesys Blog',
    GENESYS_BLOG_2 = 'Genesys Blog 2',
    DEV_CENTER = 'Developer Center',
    DEV_CENTER_2 = 'DC3',
}
