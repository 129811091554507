import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductDisplayName, ProductUid } from 'src/app/shared/enums';
import { Product } from './interfaces';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    // TODO KNOW-1500 Convert subject type from Product to ProductUid? And also change to ReplaySubject. This being initialized is causing a slight delay effect on first load.
    private _selectedProduct$: BehaviorSubject<Product> = new BehaviorSubject({
        uid: ProductUid.GENESYS_CLOUD_CX,
        displayName: ProductDisplayName[ProductUid.GENESYS_CLOUD_CX],
    });
    private _availableProducts: Product[];

    constructor() {
        this._availableProducts = Object.values(ProductUid)
            .map((uid) => ({
                uid: uid,
                displayName: ProductDisplayName[uid],
            }))
            .sort((a, b) => a.displayName.localeCompare(b.displayName)); // sort ascending A-Z
    }

    get selectedProduct$(): Observable<Product> {
        return this._selectedProduct$.asObservable();
    }

    /**
     * @deprecated Use {@link selectedProduct$} instead
     */
    get selectedProduct(): Product {
        return this._selectedProduct$.value;
    }

    get availableProducts(): Product[] {
        return this._availableProducts;
    }

    /**
     * Emits the next product to observers. Never call this method directly.
     * Update the selected product by way of query parameters via the {@link _ProductQueryParamService}.
     * This function should only be called from AppComponent!
     *
     * @param uid unique id of the product to select
     */
    setSelectedProduct(uid: ProductUid): void {
        if (!uid) {
            throw new Error('Unable to update product due to a falsy UID');
        }

        if (uid === this._selectedProduct$.value.uid) {
            // This condition can be met when the app detected a valid
            // product uid in a query parameter and already ran this function.
            // This guards us from an infinite loop from
            // query param -> this function -> update query param -> this function -> etc forever
            return;
        }

        this._selectedProduct$.next({
            uid: uid,
            displayName: ProductDisplayName[uid],
        });
    }
}
