export const environment = {
    name: 'dev',
    production: false,
    appBaseHref: '/',
    rootUrl: 'http://braintab.dev.genesyscsdteng.com',
    gkn: {
        url: 'https://know-dev.genesyscsdteng.com',
    },
    oidc: {
        // Okta Preview "Know 2.0 OIDC SPA Dev"
        clientId: '0oa28lm9qf0ERWnxv0h8',
        issuer: 'https://genesys.oktapreview.com/oauth2/default',
        redirectUri: 'https://dev.braintab.genesyscsdteng.com/login/callback',
        scopes: ['openid', 'email', 'profile'],
        pkce: true,
        testing: {
            disableHttpsCheck: true,
        },
    },
    mySupport: {
        url: 'https://genesys--uat.sandbox.my.site.com/customercare',
    },
    login: {
        showSignInWithCredentials: false,
    },
    api: {
        resourceServer: {
            favoritesUrl: 'https://know-server-dev.genesyscsdteng.com/api/v1/favorites',
        },
        cms: {
            url: 'https://know-cms.genesyscsdteng.com/api',
        },
        kepler: {
            apiAuthorizer: {
                url: 'https://kepler-authorizer-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://1c48uhvp8c.execute-api.us-east-1.amazonaws.com/dev',
            },
            coveo: {
                orgId: 'genesyssandbox',
                url: 'https://kepler-coveo-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://6lb6kosh27.execute-api.us-east-1.amazonaws.com/dev',
            },
            sfdc: {
                url: 'https://kepler-sfdc-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://1z69u0vgrh.execute-api.us-east-1.amazonaws.com/dev',
            },
        },
    },
    search: {
        pageId: 'ec922848-9004-48c6-858a-ad14677afd8a',
        searchHub: 'BrainTab',
    },
};
