import { ApplicationConfig, Injector, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { environment } from '@environments/environment';
import { OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { KeplerSfdcInterceptor } from 'src/app/shared/services';
import { routes } from './app.routes';

const oktaAuth = new OktaAuth({
    issuer: environment.oidc.issuer,
    clientId: environment.oidc.clientId,
    redirectUri: environment.oidc.redirectUri,
    scopes: environment.oidc.scopes,
});
const oktaConfig: OktaConfig = { oktaAuth };
oktaConfig.onAuthRequired = async (oktaAuth: OktaAuth, _: Injector) => {
    if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
        await oktaAuth.signInWithRedirect();
    }
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeplerSfdcInterceptor,
            multi: true,
        },
        importProvidersFrom(OktaAuthModule.forRoot(oktaConfig)),
        {
            provide: APP_BASE_HREF,
            useValue: environment.appBaseHref,
        },
    ],
};
