<section *ngIf="cases?.length > 0">
    <h2>My Support Case Updates</h2>

    <table>
        <ng-container *ngFor="let case of cases | slice: 0 : 4">
            <tr>
                <td> <span class="status"></span></td>
                <td>
                    <a href="{{ viewMyCasesUrl }}?case={{ case.case }}" target="_blank">
                        {{ case.case }}
                    </a>
                </td>
                <td>{{ case.status }}</td>
            </tr>
        </ng-container>
    </table>
    <div class="view-my-cases-link">
        <a [href]="viewMyCasesUrl" target="_blank">View All Cases</a>
    </div>
</section>
