import { FavoriteId, ProductUid, UserRoleUid } from '@enums';

export interface ConnectWithGenesysTile {
    title?: string;
    description?: string;
    urlLabel?: string;
    url?: string;
    favoriteId?: string;
    thumbnails?: ConnectWithGenesysTileThumbnails;
    userRoles?: UserRoleUid[];
    products?: ProductUid[];
}
export interface ConnectWithGenesysTileThumbnails {
    web?: string;
    mobile?: string;
    mobileFavorite?: string;
}

const _tiles: ConnectWithGenesysTile[] = [
    {
        title: 'Write a Review',
        urlLabel: 'Learn more',
        url: 'https://www.gartner.com/reviews/market/contact-center-as-a-service/vendor/genesys?utm_source=genesys&utm_medium=referral&utm_campaign=widget&utm_content=ZjczYjU0YWEtNjkwMy00MzhhLWI4NmMtN2U4YjMyMGFhMDYy',
        description:
            'Take a few minutes to share your experience with Genesys products and help other buyers make a better choice for their customer experience solutions.',
        favoriteId: FavoriteId.OWTGI_WRITE_A_REVIEW,
        products: [ProductUid.GENESYS_MULTICLOUD],
        userRoles: [UserRoleUid.PUBLIC],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/write-a-review.png',
            mobile: '/assets/images/connect-with-genesys/write-a-review.png',
            mobileFavorite: '/assets/images/connect-with-genesys/write-a-review-mobile.png',
        },
    },
    {
        title: 'Write a Review',
        urlLabel: 'Learn more',
        url: 'https://www.gartner.com/reviews/market/contact-center-as-a-service/vendor/genesys?utm_source=genesys&utm_medium=referral&utm_campaign=widget&utm_content=ZjczYjU0YWEtNjkwMy00MzhhLWI4NmMtN2U4YjMyMGFhMDYy',
        description:
            'Take a few minutes to share your experience with Genesys products and help other buyers make a better choice for their customer experience solutions.',
        favoriteId: FavoriteId.OWTGI_WRITE_A_REVIEW,
        products: [ProductUid.GENESYS_ENGAGE_ON_PREM],
        userRoles: [UserRoleUid.PUBLIC],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/write-a-review.png',
            mobile: '/assets/images/connect-with-genesys/write-a-review.png',
            mobileFavorite: '/assets/images/connect-with-genesys/write-a-review-mobile.png',
        },
    },
    {
        title: 'Customer Insider Newsletter',
        urlLabel: 'Subscribe',
        url: 'https://www.genesys.com/campaign/customer-newsletter-subscription?ost_tool=knowledgenetwork&amp;ost_campaign=noram_lg-en_knowledge_network',
        description: 'Get the latest updates, product release information, educational opportunities, and more!',
        favoriteId: FavoriteId.OWTGI_CUSTOMER_MONTHLY_NEWSLETTER,
        products: [
            ProductUid.GENESYS_CLOUD_CX,
            ProductUid.GENESYS_ENGAGE_ON_PREM,
            ProductUid.GENESYS_MULTICLOUD,
            ProductUid.POINTILLIST,
            ProductUid.PURECONNECT,
        ],
        userRoles: [UserRoleUid.AUTHENTICATED],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/customer-insider-newsletter.png',
            mobile: '/assets/images/connect-with-genesys/customer-insider-newsletter.png',
            mobileFavorite: '/assets/images/connect-with-genesys/customer-indider-newsletter-mobile.jpg',
        },
    },
    {
        title: 'Super Human Service',
        urlLabel: 'Discover how',
        url: 'https://www.genesys.com/superhuman',
        description: 'More happy customers, more happy agents – see Genesys in action.',
        favoriteId: FavoriteId.OWTGI_SUPER_HUMAN_SERVICE,
        products: [
            ProductUid.GENESYS_CLOUD_CX,
            ProductUid.GENESYS_ENGAGE_ON_PREM,
            ProductUid.GENESYS_MULTICLOUD,
            ProductUid.POINTILLIST,
            ProductUid.PURECONNECT,
        ],
        userRoles: [UserRoleUid.PUBLIC],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/super-human-service.png',
            mobile: '/assets/images/connect-with-genesys/super-human-service.png',
            mobileFavorite: '/assets/images/connect-with-genesys/super-human-service-mobile.png',
        },
    },
    {
        title: 'Product Ideas Lab',
        urlLabel: 'Browse and submit ideas',
        url: 'https://genesys.okta.com/app/genesys_ahapureengage_1/exk1fmzm9faiGxGbm0h8/sso/saml',
        description:
            'Welcome to the Genesys Multicloud CX Product Ideas Lab! We encourage you to participate by voting on, commenting on, and creating new ideas.',
        favoriteId: FavoriteId.OWTGI_PRODUCT_IDEAS_LAB,
        products: [ProductUid.GENESYS_MULTICLOUD],
        userRoles: [UserRoleUid.AUTHENTICATED],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/product-ideas-lab.png',
            mobile: '/assets/images/connect-with-genesys/product-ideas-lab.png',
            mobileFavorite: '/assets/images/connect-with-genesys/product-ideas-lab-mobile.png',
        },
    },
    {
        title: 'Product Ideas Lab',
        urlLabel: 'Browse and submit ideas',
        url: 'https://genesys.okta.com/app/genesys_ahapureengage_1/exk1fmzm9faiGxGbm0h8/sso/saml',
        description:
            'Welcome to the Genesys Engage on-premises Product Ideas Lab! We encourage you to participate by voting on, commenting on, and creating new ideas.',
        favoriteId: FavoriteId.OWTGI_PRODUCT_IDEAS_LAB,
        products: [ProductUid.GENESYS_ENGAGE_ON_PREM],
        userRoles: [UserRoleUid.AUTHENTICATED],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/product-ideas-lab.png',
            mobile: '/assets/images/connect-with-genesys/product-ideas-lab.png',
            mobileFavorite: '/assets/images/connect-with-genesys/product-ideas-lab-mobile.png',
        },
    },
    {
        title: 'Genesys User Groups',
        urlLabel: 'Join the conversation today!',
        url: 'https://www.genesys.com/campaign/genesys-user-groups',
        description:
            'Network with your peers and connect with the broader Genesys user base through these virtual meetings.',
        favoriteId: FavoriteId.OWTGI_GENESYS_USER_GROUPS,
        products: [
            ProductUid.GENESYS_CLOUD_CX,
            ProductUid.GENESYS_ENGAGE_ON_PREM,
            ProductUid.GENESYS_MULTICLOUD,
            ProductUid.PURECONNECT,
        ],
        userRoles: [UserRoleUid.AUTHENTICATED],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/user-groups.png',
            mobile: '/assets/images/connect-with-genesys/user-groups.png',
            mobileFavorite: '/assets/images/connect-with-genesys/user-groups-mobile.png',
        },
    },
    {
        title: 'Write a Review',
        urlLabel: 'Learn more',
        url: 'https://www.gartner.com/reviews/market/contact-center-as-a-service/vendor/genesys?utm_source=genesys&utm_medium=referral&utm_campaign=widget&utm_content=ZjczYjU0YWEtNjkwMy00MzhhLWI4NmMtN2U4YjMyMGFhMDYy',
        description:
            'Take a few minutes to share your experience with Genesys products and help other buyers make a better choice for their customer experience solutions.',
        favoriteId: FavoriteId.OWTGI_WRITE_A_REVIEW,
        products: [ProductUid.GENESYS_CLOUD_CX],
        userRoles: [UserRoleUid.PUBLIC],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/write-a-review.png',
            mobile: '/assets/images/connect-with-genesys/write-a-review.png',
            mobileFavorite: '/assets/images/connect-with-genesys/write-a-review-mobile.png',
        },
    },
    {
        title: 'Product Ideas Lab',
        urlLabel: 'Browse and submit ideas',
        url: 'https://genesys.okta.com/app/genesys_ahagenesyscloud_1/exk1miye2qiYV4LCp0h8/sso/saml',
        description:
            'Welcome to the Genesys Cloud Product Ideas Lab! We encourage you to participate by voting on, commenting on, and creating new ideas.',
        favoriteId: FavoriteId.OWTGI_PRODUCT_IDEAS_LAB,
        products: [ProductUid.GENESYS_CLOUD_CX],
        userRoles: [UserRoleUid.AUTHENTICATED],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/product-ideas-lab.png',
            mobile: '/assets/images/connect-with-genesys/product-ideas-lab.png',
            mobileFavorite: '/assets/images/connect-with-genesys/product-ideas-lab-mobile.png',
        },
    },
    {
        title: 'User Experience Research',
        urlLabel: 'Learn more',
        url: 'https://www.genesys.com/uxresearch',
        description:
            'We want to hear your voice. Influence Genesys products and help us shape the future of customer service.',
        favoriteId: FavoriteId.OWTGI_COLLABORATE_WITH_OUR_UX_TEAM,
        products: [ProductUid.GENESYS_CLOUD_CX],
        userRoles: [UserRoleUid.PUBLIC],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/UX-Research-300x162.png',
            mobile: '/assets/images/connect-with-genesys/UX-Research-300x162.png',
            mobileFavorite: '/assets/images/connect-with-genesys/ux-research-mobilefavorite2024.png',
        },
    },
    {
        title: 'Genesys Developer Newsletter',
        urlLabel: 'Sign Up Now',
        url: 'https://developer.genesys.cloud/devcast-newsletter               ',
        description:
            'The Developer Newsletter is a monthly publication containing feature and technical change announcements and other developer-focused news about Genesys Cloud. ',
        favoriteId: FavoriteId.OWTGI_DEVELOPER_NEWSLETTER,
        products: [ProductUid.GENESYS_CLOUD_CX],
        userRoles: [UserRoleUid.PUBLIC],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/dev-newsletter-new2024.png',
            mobile: '/assets/images/connect-with-genesys/dev-newsletter-new2024.png',
            mobileFavorite: '/assets/images/connect-with-genesys/dev-newsletter-mobilefavorite2024.png',
        },
    },
    {
        title: 'Genesys Customer Advocacy Program (GCAP)',
        urlLabel: 'Sign in/Sign up',
        url: 'https://gcaphub.genesys.com/join/Enroll',
        description:
            "As a GCAP member, you're part of an enthusiastic group built around sharing ideas and best practices, taking on activities and challenges, and being rewarded for your advocacy.",
        favoriteId: FavoriteId.OWTGI_ADVOCACY_PAYS,
        products: [
            ProductUid.GENESYS_CLOUD_CX,
            ProductUid.GENESYS_ENGAGE_ON_PREM,
            ProductUid.GENESYS_MULTICLOUD,
            ProductUid.POINTILLIST,
            ProductUid.PURECONNECT,
        ],
        userRoles: [UserRoleUid.PUBLIC],
        thumbnails: {
            web: '/assets/images/connect-with-genesys/gcap.png',
            mobile: '/assets/images/connect-with-genesys/gcap.png',
            mobileFavorite: '/assets/images/connect-with-genesys/gcap-mobile.png',
        },
    },
];

export function connectWithGenesysTileById(id: FavoriteId): ConnectWithGenesysTile {
    return _tiles.find((tile) => tile.favoriteId == id);
}
