<div #container class="container">
    <h3>My Favorites</h3>
    <ng-container *ngIf="(authStateService.authState$ | async).isAuthenticated; else signInCta">
        @defer {
            <ul #favList>
                <li *ngFor="let fav of group.favorites$ | async | slice: 0 : 5">
                    <svg width="12" height="16" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            class="star"
                            d="M0.812881 8.45108L8.34703 7.35612L11.715 0.528203C11.807 0.341259 11.9583 0.189923 12.1453 0.0979342C12.6141 -0.133521 13.1839 0.0593584 13.4183 0.528203L16.7862 7.35612L24.3204 8.45108C24.5281 8.48076 24.718 8.57868 24.8634 8.72705C25.0392 8.90772 25.1361 9.15079 25.1327 9.40284C25.1294 9.6549 25.0261 9.89531 24.8456 10.0713L19.3946 15.3858L20.6824 22.8903C20.7126 23.0649 20.6933 23.2444 20.6266 23.4086C20.56 23.5727 20.4487 23.7149 20.3053 23.819C20.162 23.9231 19.9923 23.985 19.8156 23.9976C19.6389 24.0102 19.4622 23.973 19.3055 23.8903L12.5666 20.3473L5.82774 23.8903C5.64376 23.9882 5.43011 24.0209 5.22536 23.9853C4.70904 23.8962 4.36186 23.4066 4.45088 22.8903L5.73872 15.3858L0.287657 10.0713C0.139288 9.92587 0.0413647 9.73595 0.0116901 9.52824C-0.068428 9.00895 0.293591 8.52823 0.812881 8.45108Z" />
                    </svg>
                    <a
                        [href]="fav?.id?.startsWith('owtgi') ? fav.urlPath : gknUrl + fav.urlPath + '#' + fav.id"
                        target="_blank"
                        ref="nofollow">
                        {{ fav.label }}
                    </a>
                </li>
            </ul>
        } @placeholder (minimum 500ms) {
            <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
        }

        @defer {
            <ng-container *ngIf="group.favorites$ | async as favs">
                <a
                    class="manage"
                    [href]="gknUrl + '/home?product=' + group.uid + '&location=just-for-me-my-favorites'"
                    target="_blank"
                    ref="nofollow">
                    {{ favs.length > 0 ? 'Manage Favorites' : 'No favorites found! Open the GKN.' }}
                </a>
            </ng-container>
        }
    </ng-container>
    <ng-template #signInCta>
        <span *ngIf="!(authStateService.authState$ | async).isAuthenticated">
            <button class="sign-in-link" (click)="handleLogin($event)">Sign in</button> to view your Favorites.
        </span>
    </ng-template>
</div>
