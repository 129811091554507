/**
 * Enumerates roles that categorize GKN users.
 *
 * These map 1:1 to Know CMS (where editing is disabled) to
 * allow content filtering against roles with some compile
 * time type safety. Do not change the string values without
 * also updating Know CMS.
 */
export enum UserRoleUid {
    PUBLIC = 'public',
    AUTHENTICATED = 'authenticated',
    CUSTOMER = 'customer',
    PARTNER = 'partner',
    EMPLOYEE = 'employee', // note: CSS and CSDT employees do not have a special role, but flags are available via UserService
    CSM_TAM = 'csm-tam',
    CONSULTANT = 'consultant',
}

/**
 * Singly linked list representing the hierarchy of user role relationships
 */
export const UserRoleHierarchy: Record<UserRoleUid, UserRoleHierarchyNode> = {
    [UserRoleUid.PUBLIC]: {
        val: UserRoleUid.PUBLIC,
        next: null,
    },
    [UserRoleUid.AUTHENTICATED]: {
        val: UserRoleUid.AUTHENTICATED,
        next: {
            val: UserRoleUid.PUBLIC,
            next: null,
        },
    },
    [UserRoleUid.CUSTOMER]: {
        val: UserRoleUid.CUSTOMER,
        next: {
            val: UserRoleUid.AUTHENTICATED,
            next: {
                val: UserRoleUid.PUBLIC,
                next: null,
            },
        },
    },
    [UserRoleUid.PARTNER]: {
        val: UserRoleUid.PARTNER,
        next: {
            val: UserRoleUid.AUTHENTICATED,
            next: {
                val: UserRoleUid.PUBLIC,
                next: null,
            },
        },
    },
    [UserRoleUid.CONSULTANT]: {
        val: UserRoleUid.CONSULTANT,
        next: {
            val: UserRoleUid.EMPLOYEE,
            next: {
                val: UserRoleUid.AUTHENTICATED,
                next: {
                    val: UserRoleUid.PUBLIC,
                    next: null,
                },
            },
        },
    },
    [UserRoleUid.EMPLOYEE]: {
        val: UserRoleUid.EMPLOYEE,
        next: {
            val: UserRoleUid.AUTHENTICATED,
            next: {
                val: UserRoleUid.PUBLIC,
                next: null,
            },
        },
    },
    [UserRoleUid.CSM_TAM]: {
        val: UserRoleUid.CSM_TAM,
        next: {
            val: UserRoleUid.EMPLOYEE,
            next: {
                val: UserRoleUid.AUTHENTICATED,
                next: {
                    val: UserRoleUid.PUBLIC,
                    next: null,
                },
            },
        },
    },
};

export type UserRoleHierarchyNode = {
    val: UserRoleUid;
    next: UserRoleHierarchyNode | null;
};
