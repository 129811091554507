import {
    KeplerMeta,
    SalesforceAccountId,
    SalesforceAttributes,
    SalesforceCaseId,
    SalesforceContactId,
    SalesforceProfileId,
    SalesforceUserId,
} from '@types';

/* eslint-disable */

export interface KeplerSfdcUserDetailResponse {
    meta?: KeplerMeta;
    data?: KeplerSfdcUserDetail;
}

export interface KeplerSfdcUserDetail {
    Account?: KeplerSfdcAccount;
    AccountId?: SalesforceAccountId;
    Advisor_User?: KeplerSfdcUser;
    Badges?: KeplerSfdcBadge[];
    Cases?: KeplerSfdcCase[];
    /** @deprecated use data.Contacts instead */
    Contact?: KeplerSfdcContact[];
    Contacts?: KeplerSfdcContact[];
    CSM_User?: KeplerSfdcUser;
    Docebo_User?: KeplerSfdcDoceboUser;
    Profile?: KeplerSfdcProfile;
    Support_Access?: KeplerSfdcSupport_Access__c[];
    Support_Access_Cloud?: KeplerSfdcSupport_Access_Cloud__c[];
    User?: KeplerSfdcUser;
    User_Groups?: string[];
    Permissions?: {
        MySupport: boolean;
    };
}

export interface KeplerSfdcAccount {
    attributes?: SalesforceAttributes;
    Id?: string;
    Name?: string;
}

export interface KeplerSfdcBadge {
    attributes?: SalesforceAttributes;
    Id?: string;
    Name?: string;
    Contact__c?: string;
}

export interface KeplerSfdcCase {
    attributes?: SalesforceAttributes;
    CaseNumber?: string;
    Id?: SalesforceCaseId;
    ClosedDate?: string;
    Cloud_Deployment_Name__c?: string;
    ContactEmail?: string;
    CreatedDate?: string;
    LastModifiedDate?: string;
    Priority?: string;
    Service_Request_Type__c?: string;
    Status?: string;
    Sub_Status__c?: string;
    Subject?: string;
}

export interface KeplerSfdcContact {
    attributes?: SalesforceAttributes;
    Customer_Portal_User__c?: boolean;
    Email?: string;
    Id?: SalesforceContactId;
    Partner_Portal_Access__c?: boolean;
    Status__c?: string;
    Usage_Portal__c?: string;
    Country__r?: KeplerSfdcCountry;
    Country__c?: string;
    AccountId?: SalesforceAccountId;
    Support_Access_Type__c?: string;
}

export interface KeplerSfdcCountry {
    attributes?: SalesforceAttributes;
    Id?: string;
    Name?: string;
}

export interface KeplerSfdcDoceboUser {
    attributes?: SalesforceAttributes;
    Id?: string;
    Name?: string;
    docebo_v3__UserId__c?: number;
    docebo_v3__Contact__c?: SalesforceContactId;
}

export interface KeplerSfdcProfile {
    attributes?: SalesforceAttributes;
    Id?: SalesforceProfileId;
    Name?: string;
}

interface KeplerSfdcSupport_Access {
    attributes?: SalesforceAttributes;
    Name?: string;
    Contact__c?: SalesforceContactId;
    Id?: string;
}

export interface KeplerSfdcSupport_Access__c extends KeplerSfdcSupport_Access {
    BOA_Platform__c?: string;
    Email__c?: string;
    Status__c?: string;
    Back_Office_Account__c?: string;
    Contact_Name__c?: string;
}

export interface KeplerSfdcSupport_Access_Cloud__c extends KeplerSfdcSupport_Access {
    CD_Platform__c?: string;
    Contact_Account_Name__c?: string;
    Access_Level__c?: string;
    Access_Status__c?: string;
    Cloud_Deployment__c?: string;
    Id?: string;
    Cloud_Deployment__r?: KeplerSfdcSupport_Access_Cloud_CloudDeployment;
}

export interface KeplerSfdcSupport_Access_Cloud_CloudDeployment {
    attributes?: SalesforceAttributes;
    Id?: string;
    Support_Status__c?: string;
    AWS_Region__c?: string;
    PureCloud_OrgID__c?: string;
    Account__c?: string;
    Account__r?: KeplerSfdcAccount;
}

export interface KeplerSfdcUser {
    AccountId?: SalesforceAccountId;
    /** @deprecated use data.Contacts?.[0].Id instead */
    ContactId?: SalesforceContactId;
    Email?: string;
    EVP__c?: string;
    GCAP_User__c?: boolean;
    FirstName?: string;
    Id?: SalesforceUserId;
    IsActive?: boolean;
    LastName?: string;
    Name?: string;
    Organization__c?: string;
    ProfileId?: SalesforceProfileId;
    Title?: string;
    User_License_Type__c?: string;
    Phone?: string;
    UserType?: string;
}

export enum CaseStatusEnum {
    AWAITING_INFO = 'Awaiting Info',
    SOLUTION_PROPOSED = 'Solution Proposed',
    CLOSED = 'Closed',
    OPEN = 'Open',
}
