/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CommonModule, DOCUMENT } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { ProductDisplayName, ProductUid } from 'src/app/shared/enums';
import { SafePipe } from 'src/app/shared/pipes';
import { KeplerCoveoSearchPage, KeplerCoveoService } from 'src/app/shared/services';
import { scrollToTopScrolling } from 'src/app/shared/utils';

@Component({
    selector: 'app-brain-tab-search-results',
    standalone: true,
    imports: [CommonModule, SafePipe],
    templateUrl: './brain-tab-search-results.component.html',
    styleUrls: ['./brain-tab-search-results.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class BrainTabSearchResultsComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];
    @Input() product: ProductUid;

    @ViewChild('searchContainer') searchContainer: ElementRef;
    searchPageHtml$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        private cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private keplerCoveoService: KeplerCoveoService,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.keplerCoveoService
                .searchPage$(environment.search.pageId)
                .pipe(
                    tap((searchPage: KeplerCoveoSearchPage) => {
                        // Apply the HTML, but note that it won't be actually render until initializeCoveo() terminates.
                        this.searchPageHtml$.next(searchPage?.html);
                        this.cdr.markForCheck();

                        // Load each CSS page from the API response
                        for (let i = 0; i < searchPage?.css?.length; i++) {
                            const url = searchPage.css[i].URL;
                            if (url && url != '') {
                                this.loadCss(url);
                            }
                        }
                        this.initializeCoveo();
                    }),
                )
                .subscribe(),
        );
    }

    /**
     * Loads the given CSS URL into the DOM.
     * @param url to load
     */
    private loadCss(url: string): void {
        const link = this.document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;

        // Stylesheets should go into <html> -> <head>
        this.document.getElementsByTagName('head')[0].appendChild(link);
    }

    /**
     * Initialize Coveo UI.
     * This should be done after the HTML and CSS is loaded!
     *
     * Subscribes to retrieve the Coveo Search Token that includes the DOM and CSS to inject into the page.
     * The Javascript required is imported via index.html
     */
    private initializeCoveo(): void {
        this.subs.push(
            this.keplerCoveoService
                .searchToken$(environment.search.searchHub)
                .pipe(
                    tap((searchToken) => {
                        // Sets up configuration for pulling our Coveo search page
                        // @ts-ignore
                        Coveo.SearchEndpoint.configureCloudV2Endpoint(environment.api.kepler.coveo.orgId, searchToken);

                        const hostedSearchPage = this.document.getElementById('search');

                        // Configures defaults to set after the search page is initialized
                        // @ts-ignore
                        Coveo.$$(hostedSearchPage).on('afterInitialization', () => this.runPostInitCustomizations());

                        // Initialize Coveo!
                        // @ts-ignore
                        Coveo.init(hostedSearchPage).then((_success) => {
                            // Handle some extra things after Coveo is initialized
                            this.runPostSearchCustomizations();
                        });
                    }),
                )
                .subscribe(),
        );
    }

    /**
     * Sets up search interface according to query search parameters set in the url:
     *  - `product` will set the product facet
     *
     * This must be executed as part of the `afterInitialization` event listener!
     *
     */
    private runPostInitCustomizations(): void {
        // Root Search Element
        // @ts-ignore
        const searchElement = Coveo.$$(this.document as unknown as Coveo.Dom);

        // Update the filtered product selection to match the current selected product.
        const productFacetElement = searchElement.find(".CoveoDynamicFacet[data-field='@genesysproductline'");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const productFacetInstance = Coveo.get(productFacetElement) as Coveo.Facet;

        if (productFacetInstance) {
            productFacetInstance.reset();

            const productName = ProductDisplayName[this.product];
            productFacetInstance.selectValue(productName);
        }
    }

    /**
     * Customizations for after a search has been submitted
     */
    private async runPostSearchCustomizations(): Promise<void> {
        scrollToTopScrolling();
        this.cdr.markForCheck();
    }

    ngOnDestroy(): void {
        this.subs?.forEach((sub) => sub?.unsubscribe());
    }
}
