<div class="default">
    <input
        class="search-icon margin-left-small margin-right-smaller"
        type="image"
        src="/assets/icons/search.svg"
        (click)="handleSearch($event)"
        aria-label="Search icon"
        [attr.data-analytics]="'search-icon'" />
    <input
        type="text"
        [formControl]="searchTerm"
        [placeholder]="placeholder"
        (keydown.enter)="handleSearch($event)"
        aria-label="Search bar"
        [attr.data-analytics]="'search-bar'" />
    <input
        *ngIf="searchTerm.value"
        type="image"
        src="/assets/icons/close.svg"
        class="clear-icon"
        (click)="handleClear()"
        width="15px"
        height="15px"
        alt="Clear search"
        aria-label="Clear search"
        [attr.data-analytics]="'search-clear'" />
</div>
