import { environment } from '@environments/environment';
import { ProductDisplayName, ProductUid, UserRoleUid } from 'src/app/shared/enums';
import { NavGroup } from './brain-tab-nav-group.interface';

const ALL_ROLES = Object.values(UserRoleUid);
const AUTHED_ROLES = ALL_ROLES.filter((uid) => uid !== UserRoleUid.PUBLIC);

export const NAV_GROUPS: NavGroup[] = [
    {
        uid: ProductUid.GENESYS_CLOUD_CX,
        displayName: ProductDisplayName[ProductUid.GENESYS_CLOUD_CX],
        rank: 1,
        links: [
            {
                title: 'AppFoundry',
                url: 'https://appfoundry.genesys.com/filter/genesyscloud',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Beyond Training',
                url: 'https://beyond.genesys.com/explore/subscription/genesys-cloud-learning-subscription',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Community',
                url: 'https://community.genesys.com/communities/genesyscloudcx?CommunityKey=bab95e9c-6bbe-4a13-8ade-8ec0faf733d4',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Consultant Portal',
                url: 'http://genesyspartner.force.com/ConsultantPortal',
                userRoles: [UserRoleUid.CONSULTANT],
            },
            {
                title: 'Developer Center',
                url: 'https://developer.genesys.cloud',
                userRoles: ALL_ROLES,
            },
            {
                title: 'GCAP Hub',
                url: 'https://gcaphub.genesys.com/users/sign_up?join-code=Enroll',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Genesys.com',
                url: 'https://genesys.com',
                userRoles: ALL_ROLES,
            },
            {
                title: 'My Support',
                url: environment.mySupport.url,
                userRoles: [UserRoleUid.CUSTOMER, UserRoleUid.PARTNER],
            },
            {
                title: 'Partner Portal',
                url: 'https://genesyspartner.force.com/GenesysPartner/s/',
                userRoles: [UserRoleUid.PARTNER],
            },
            {
                title: 'Product Ideas Lab',
                url: 'https://genesys.okta.com/app/genesys_ahagenesyscloud_1/exk1miye2qiYV4LCp0h8/sso/saml',
                userRoles: AUTHED_ROLES,
            },
            {
                title: 'Resource Center',
                url: 'https://help.mypurecloud.com',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Status',
                url: 'https://status.mypurecloud.com',
                userRoles: ALL_ROLES,
            },
            {
                title: 'UX Research Program',
                url: 'https://www.genesys.com/uxresearch',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Genesys Cloud Login',
                url: 'https://login.mypurecloud.com/#/authenticate',
                userRoles: ALL_ROLES,
            },
        ],
    },
    {
        uid: ProductUid.GENESYS_ENGAGE_ON_PREM,
        displayName: ProductDisplayName[ProductUid.GENESYS_ENGAGE_ON_PREM],
        rank: 2,
        links: [
            {
                title: 'Beyond Training',
                url: 'https://beyond.genesys.com/explore/subscription/genesys-engage-learning-subscription',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Community',
                url: 'https://community.genesys.com/communities/genesysengage?CommunityKey=04e76a4a-2a03-4059-ab66-82e058125372',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Consultant Portal',
                url: 'http://genesyspartner.force.com/ConsultantPortal',
                userRoles: [UserRoleUid.CONSULTANT],
            },
            {
                title: 'Developer Center',
                url: 'https://developer.genesyscloud.com/',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Documentation',
                url: 'https://docs.genesys.com/Documentation/OnPremises',
                userRoles: ALL_ROLES,
            },
            {
                title: 'GCAP Hub',
                url: 'https://gcaphub.genesys.com/users/sign_up?join-code=Enroll',
                userRoles: ALL_ROLES,
            },
            {
                title: 'My Support',
                url: environment.mySupport.url,
                userRoles: [UserRoleUid.CUSTOMER, UserRoleUid.PARTNER],
            },
            {
                title: 'Partner Portal',
                url: 'https://genesyspartner.force.com/GenesysPartner/s/',
                userRoles: [UserRoleUid.PARTNER],
            },
            {
                title: 'Product Ideas Lab',
                url: 'https://genesys.okta.com/app/genesys_ahapureengage_1/exk1fmzm9faiGxGbm0h8/sso/saml',
                userRoles: AUTHED_ROLES,
            },
        ],
    },
    {
        uid: ProductUid.GENESYS_MULTICLOUD,
        displayName: ProductDisplayName[ProductUid.GENESYS_MULTICLOUD],
        rank: 3,
        links: [
            {
                title: 'Beyond Training',
                url: 'https://beyond.genesys.com/explore/subscription/genesys-engage-learning-subscription',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Community',
                url: 'https://community.genesys.com/communities/genesysmulticloudcx?CommunityKey=1d65ddfe-95e6-4f83-a3a4-961f097e0a84',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Consultant Portal',
                url: 'http://genesyspartner.force.com/ConsultantPortal',
                userRoles: [UserRoleUid.CONSULTANT],
            },
            {
                title: 'Developer Center',
                url: 'https://developer.genesyscloud.com/',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Documentation',
                url: 'https://docs.genesys.com/Documentation/Cloud',
                userRoles: ALL_ROLES,
            },
            {
                title: 'GCAP Hub',
                url: 'https://gcaphub.genesys.com/users/sign_up?join-code=Enroll',
                userRoles: ALL_ROLES,
            },
            {
                title: 'My Support',
                url: environment.mySupport.url,
                userRoles: [UserRoleUid.CUSTOMER, UserRoleUid.PARTNER],
            },
            {
                title: 'Partner Portal',
                url: 'https://genesyspartner.force.com/GenesysPartner/s/',
                userRoles: [UserRoleUid.PARTNER],
            },
            {
                title: 'Product Ideas Lab',
                url: 'https://genesys.okta.com/app/genesys_ahapureengage_1/exk1fmzm9faiGxGbm0h8/sso/saml',
                userRoles: AUTHED_ROLES,
            },
        ],
    },
    {
        uid: ProductUid.PURECONNECT,
        displayName: ProductDisplayName[ProductUid.PURECONNECT],
        rank: 4,
        links: [
            {
                title: 'Community',
                url: 'https://community.genesys.com/communities/pureconnect?CommunityKey=cf214c8f-5206-4010-9b2c-2085cbd65a44',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Consultant Portal',
                url: 'http://genesyspartner.force.com/ConsultantPortal',
                userRoles: [UserRoleUid.CONSULTANT],
            },
            {
                title: 'Developer Portal',
                url: 'https://developer.inin.com',
                userRoles: ALL_ROLES,
            },
            {
                title: 'Documentation',
                url: 'https://help.genesys.com/cic/desktop/pureconnect_documentation.htm',
                userRoles: ALL_ROLES,
            },
            {
                title: 'GCAP Hub',
                url: 'https://gcaphub.genesys.com/users/sign_up?join-code=Enroll',
                userRoles: ALL_ROLES,
            },
            {
                title: 'My Support',
                url: environment.mySupport.url,
                userRoles: [UserRoleUid.CUSTOMER, UserRoleUid.PARTNER],
            },
            {
                title: 'Partner Portal',
                url: 'https://genesyspartner.force.com/GenesysPartner/s/',
                userRoles: [UserRoleUid.PARTNER],
            },
            {
                title: 'Resource Center',
                url: 'https://help.mypurecloud.com',
                userRoles: ALL_ROLES,
            },
        ],
    },
];
